import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios"; // This will be used for fetching metadata
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css"; // Ensure to add relevant CSS as per the instructions

function ShibariTechniqueDriller() {
  const [techniques, setTechniques] = useState([]);
  const [filteredTechniques, setFilteredTechniques] = useState([]);
  const [displayedTechniques, setDisplayedTechniques] = useState([]); // For techniques being displayed
  const [techniquesPerLoad] = useState(30); // Number of techniques to load per batch
  const [isRandom, setIsRandom] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // Current page of techniques being displayed
  const [filters, setFilters] = useState({
    categories: "All",
    authors: "All",
    platforms: "All",
    bodyParts: "All",
    searchQuery: "",
  });
  const [expandedTechnique, setExpandedTechnique] = useState(null); // Track which technique is expanded

  // Fetch techniques from the external JSON file
  useEffect(() => {
    fetch("/kyohon.json")
      .then((response) => response.json())
      .then((data) => {
        setTechniques(data);
        setFilteredTechniques(data); // Initially show all techniques
      })
      .catch((error) => console.error("Error fetching techniques:", error));
  }, []);

  // Memoize unique values for dropdowns
  const uniqueValues = useMemo(() => {
    return {
      categories: ["All", ...new Set(techniques.map((t) => t.category))],
      authors: ["All", ...new Set(techniques.map((t) => t.author))],
      platforms: ["All", ...new Set(techniques.map((t) => t.platform))],
      bodyParts: ["All", ...new Set(techniques.flatMap((t) => t.bodyParts))],
    };
  }, [techniques]);

  const shuffleArray = (array) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    console.log(shuffledArray[0]);
    setIsRandom(true);
    return [shuffledArray[0]];
  };

  // Shuffle the filtered techniques when the header is clicked
  const handleHeaderClick = () => {
    const shuffledTechniques = shuffleArray(filteredTechniques);
    setDisplayedTechniques(shuffledTechniques); // Update the displayed techniques with the shuffled array
  };

  // Handle filter changes, including search query
  const handleFilterChange = useCallback((filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
    setCurrentPage(1); // Reset pagination when filters are changed
  }, []);

  // Helper function to search across multiple fields
  const matchesSearchQuery = (technique, query) => {
    const lowerCaseQuery = query.toLowerCase();
    const nameMatches = technique.name.toLowerCase().includes(lowerCaseQuery);
    const descriptionMatches = technique.description
      ? technique.description.toLowerCase().includes(lowerCaseQuery)
      : false;
    const bodyPartsMatches = technique.bodyParts.some((part) =>
      part.toLowerCase().includes(lowerCaseQuery)
    );
    const tagsMatches = technique.tags
      ? technique.tags.some((tag) => tag.toLowerCase().includes(lowerCaseQuery))
      : false;

    return nameMatches || descriptionMatches || bodyPartsMatches || tagsMatches;
  };

  // Filter techniques based on the selected filters and search query
  const filterTechniques = useCallback(() => {
    return techniques.filter((technique) => {
      // if (technique.author === "Unknown" || technique.category === "Tutorial") {
      //   return null;
      // }

      const matchesCategory =
        filters.categories === "All" ||
        technique.category === filters.categories;
      const matchesAuthor =
        filters.authors === "All" || technique.author === filters.authors;
      const matchesPlatform =
        filters.platforms === "All" || technique.platform === filters.platforms;
      const matchesBodyParts =
        filters.bodyParts === "All" ||
        technique.bodyParts.includes(filters.bodyParts);
      const matchesSearchQueryField =
        filters.searchQuery === "" ||
        matchesSearchQuery(technique, filters.searchQuery);

      return (
        matchesCategory &&
        matchesAuthor &&
        matchesPlatform &&
        matchesBodyParts &&
        matchesSearchQueryField
      );
    });
  }, [techniques, filters]);

  // Re-filter techniques whenever filters or techniques change
  useEffect(() => {
    const newFilteredTechniques = filterTechniques();
    setFilteredTechniques(newFilteredTechniques);
    setIsRandom(false);
    setDisplayedTechniques(newFilteredTechniques.slice(0, techniquesPerLoad)); // Load the first batch
  }, [filterTechniques, techniquesPerLoad]);

  // Function to load more techniques when scrolling
  const loadMoreTechniques = useCallback(() => {
    const nextPage = currentPage + 1;
    const nextTechniques = filteredTechniques.slice(
      0,
      nextPage * techniquesPerLoad
    );
    setDisplayedTechniques(nextTechniques);
    setCurrentPage(nextPage);
  }, [currentPage, filteredTechniques, techniquesPerLoad]);

  // Scroll event listener to detect when to load more techniques
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop + windowHeight >= documentHeight - 50) {
        !isRandom && loadMoreTechniques();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loadMoreTechniques, isRandom]);

  // Handle expanding/collapsing techniques
  const handleExpandTechnique = (techniqueUrl) => {
    if (expandedTechnique === techniqueUrl) {
      setExpandedTechnique(null);
    } else {
      setExpandedTechnique(techniqueUrl);
    }
  };

  return (
    <div>
      <div onClick={handleHeaderClick} style={{ cursor: "pointer" }}>
        <Header />
      </div>
      <div className="app-container">
        {/* Search Box */}
        <div className="search-box">
          <input
            type="text"
            placeholder="Search..."
            value={filters.searchQuery}
            onChange={(event) =>
              handleFilterChange("searchQuery", event.target.value)
            }
          />
        </div>

        {/* Filter Options */}
        <div className="filter-container">
          <Dropdown
            label="Category"
            options={uniqueValues.categories}
            selected={filters.categories}
            onChange={(event) =>
              handleFilterChange("categories", event.target.value)
            }
          />
          <Dropdown
            label="Author"
            options={uniqueValues.authors}
            selected={filters.authors}
            onChange={(event) =>
              handleFilterChange("authors", event.target.value)
            }
          />
          <Dropdown
            label="Platform"
            options={uniqueValues.platforms}
            selected={filters.platforms}
            onChange={(event) =>
              handleFilterChange("platforms", event.target.value)
            }
          />
          {/* <Dropdown
            label="Body Part"
            options={uniqueValues.bodyParts}
            selected={filters.bodyParts}
            onChange={(event) =>
              handleFilterChange("bodyParts", event.target.value)
            }
          /> */}
        </div>
        <div className="count">
          {filteredTechniques.length} of {techniques.length}
        </div>
        {/* List of Displayed Techniques */}
        <div className="technique-list">
          {displayedTechniques.length > 0 ? (
            displayedTechniques.map((technique) => (
              <Technique
                key={technique.url}
                technique={technique}
                isExpanded={expandedTechnique === technique.url}
                onExpand={handleExpandTechnique}
              />
            ))
          ) : (
            <p>No techniques match your filter.</p>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

// Technique component - Loads image on title click, one open at a time
function Technique({ technique, isExpanded, onExpand }) {
  const [previewImage, setPreviewImage] = useState(null); // State for preview image

  const {
    name: originalName,
    author,
    platform,
    description,
    url,
    tags,
  } = technique;

  // If the name ends with " | Shibari Study", remove it
  const name = originalName.replace(" | Shibari Study", "");

  const isYouTube = platform.toLowerCase().includes("youtube");

  // Function to extract YouTube video ID
  const getYouTubeVideoId = (url) => {
    const match = url.match(/[?&]v=([^&#]*)/);
    return match ? match[1] : null;
  };

  // Fetch Open Graph (OG) metadata for non-YouTube platforms (only when expanded)
  useEffect(() => {
    if (isExpanded && !previewImage) {
      const fetchMetadata = async () => {
        if (!isYouTube && url) {
          try {
            const response = await axios.get(
              `https://api.microlink.io/?url=${url}`
            );
            const fetchedData = response.data?.data;
            if (fetchedData?.image?.url) {
              setPreviewImage(fetchedData.image.url);
            }
          } catch (error) {
            console.error("Error fetching metadata:", error);
          }
        } else if (isYouTube) {
          const videoId = getYouTubeVideoId(url);
          setPreviewImage(
            `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
          );
        }
      };

      fetchMetadata();
    }
  }, [isExpanded, url, isYouTube, previewImage]);

  return (
    <>
      {/* Title */}
      <div className="technique-item">
        <h2 className="technique-title">{name}</h2>
        <h3 className="technique-author">{author !== "Unknown" && author}</h3>

        <div className="technique-content">
          <p className="technique-description">
            {description || "No description available"}
          </p>

          {tags && tags.length > 0 && (
            <p className="technique-tags">{tags.join(" - ")}</p>
          )}
          <a
            className="learn-more-btn"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View on {platform}
          </a>
        </div>
      </div>
      <hr />
    </>
  );

  // ) : (
  //   <div className={`technique-row ${isExpanded ? "expanded" : ""}`}>
  //     {/* Title */}
  //     <div className="technique-item" onClick={() => onExpand(technique.url)}>
  //       <h2 className="technique-title">{name}</h2>
  //       <h3 className="technique-author">{author}</h3>
  //     </div>

  //     {/* Technique Details */}
  //     {isExpanded && (
  //       <div
  //         className="technique-info"
  //         style={{
  //           backgroundImage:
  //             !isYouTube && previewImage ? `url(${previewImage})` : "none",
  //           backgroundSize: "cover",
  //           backgroundPosition: "center",
  //         }}
  //       >
  //         {/* Technique Information */}
  //         <div className="technique-content">
  //           <p className="technique-description">
  //             {description || "No description available"}
  //           </p>

  //           {tags && tags.length > 0 && (
  //             <p className="technique-tags">{tags.join(" - ")}</p>
  //           )}

  //           {url && (
  //             <div>
  //               {isYouTube ? (
  //                 <div className="video-container">
  //                   <iframe
  //                     src={`https://www.youtube.com/embed/${getYouTubeVideoId(
  //                       url
  //                     )}`}
  //                     title={name}
  //                     frameBorder="0"
  //                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //                     allowFullScreen
  //                   ></iframe>
  //                 </div>
  //               ) : (
  //                 <a
  //                   className="learn-more-btn"
  //                   href={url}
  //                   target="_blank"
  //                   rel="noopener noreferrer"
  //                 >
  //                   View on {platform}
  //                 </a>
  //               )}
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     )}
  //   </div>
  // );
}

// Dropdown component
function Dropdown({ label, options, selected, onChange }) {
  return (
    <div className="dropdown-container">
      <label>{label}</label>
      <select value={selected} onChange={onChange}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}

export default ShibariTechniqueDriller;
