import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="disclaimer"></div>

        <div>Made by Oz Thorn</div>
      </div>
    </>
  );
}
